@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons+Outlined);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.md-48 {
  font-size: 48px;
}

.md-36 {
  font-size: 36px;
}

.light-icon {
  color: #5f697c;
}

html, body {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

#root {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 50px 1fr;
      grid-template-rows: 50px 1fr;
  height: 100%;
  width: 100%;
}

#headerWrapper {
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
}

#homeList {
  display: -ms-grid;
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  justify-content: center;
  margin-top: 75px;
}

#homeList .listItem {
  border-bottom: 1px solid white;
  color: #e8e8e8;
  text-decoration: none;
  font-size: 2.5rem;
  word-wrap: break-word;
  padding-bottom: 35px;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
}

#homeList .listItem:last-child {
  border-bottom: none;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #e8e8e8;
  text-decoration: none;
  display: flex;
  align-items: center;
}

#gameWrapper {
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 75px 1fr;
      grid-template-rows: 75px 1fr;
}

#gameWrapper #gameTitle {
  display: flex;
  justify-content: center;
}

#gameWrapper #gameTitle #settingsBtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
}

#gameWrapper #fprWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 3fr 1fr;
      grid-template-rows: 3fr 1fr;
}

#gameWrapper #fprWrapper #spinnerOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
}

#gameWrapper #fprWrapper #spinnerOuter #person {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 2.5rem;
}

#gameWrapper #fprWrapper #spinnerOuter .spinner_wrapper {
  width: 300px;
  height: 70px;
  position: relative;
  overflow: hidden;
  border: 1px solid #9c9c9c;
  border-radius: 50px;
}

#gameWrapper #fprWrapper #spinnerOuter #action_spinner {
  right: 0%;
  top: -267px;
}

#gameWrapper #fprWrapper #spinnerOuter #action_spinner .pie_item {
  transform-origin: left;
  right: 0%;
  padding-right: 5px;
}

#gameWrapper #fprWrapper #body_spinner {
  left: 0%;
  top: -267px;
}

#gameWrapper #fprWrapper #body_spinner .pie_item {
  transform-origin: right;
  left: 0%;
  padding-left: 5px;
}

#gameWrapper #fprWrapper .spinner {
  width: 600px;
  height: 600px;
  border-radius: 50%;
  position: absolute;
  transition: all ease 1s;
}

#gameWrapper #fprWrapper .spinner .pie_item {
  height: 50%;
  width: 50%;
  position: absolute;
  top: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
}

#btnWrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 4fr 6fr;
      grid-template-columns: 4fr 6fr;
  height: 100%;
}

#btnWrapper button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-weight: bolder;
  font-size: 2.5rem;
  color: #282c34;
  transition: all ease 0.25s;
}

#btnWrapper #btn_continue {
  background-color: #6ddb6d;
}

#btnWrapper #btn_continue:active {
  background-color: #238c23;
}

#btnWrapper #btn_reroll {
  background-color: #f2f955;
}

#btnWrapper #btn_reroll:active {
  background-color: #a8af06;
}

#btnWrapper #btn_reroll {
  grid-template: reroll;
}
/*# sourceMappingURL=main.css.map */
